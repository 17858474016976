import { isSubscribeEmailModalOpen } from "../../../selectors/announcement";
import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import "./styles.css";
import WebsiteModal from "../../Modal";
import { createToggleSubscribeModalAction } from "../../../actions/modals";
import MailChimp from "../../Content/Subscribe/MailChimp";

const SubscribeModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isSubscribeEmailModalOpen);

  const handleClose = useCallback(() => {
    dispatch(createToggleSubscribeModalAction({ isOpen: false }));
  }, [dispatch]);

  return (
    <WebsiteModal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby={"Subscribe e-mail"}
    >
      <>
        <h1>Subscribe to Updates</h1>
        <p>
          Subscribe to our newsletter to receive updates from New Peking Chinese
          Restaurant. Subscribes receive periodic promotions, discounts, and
          updates from our restaurant.
        </p>

        <MailChimp action="https://newpeking.us7.list-manage.com/subscribe/post?u=f752ccede7e03daf316395f77&amp;id=95f5ec7e74" />
      </>
    </WebsiteModal>
  );
};

export default SubscribeModal;
