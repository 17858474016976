import { useDispatch } from "react-redux";
import React, { useCallback, useEffect } from "react";
import { createToggleSubscribeModalAction } from "./actions/modals";
import AnnouncementModal from "./components/Announcement/Modal";
import SubscribeModal from "./components/SubscribeEmail/Modal";
import OrderingModal from "./components/Ordering/Modal";
import Header from "./components/Header";
import ImageCarousel from "./components/Content/ImageCarousel";
import HoursAndLocation from "./components/Content/HoursAndLocation";
import Subscribe from "./components/Content/Subscribe";
import About from "./components/Content/About";
import Footer from "./components/Footer";
import MenuContent from "./components/Content/MenuContent";

export enum View {
  HOME,
  MENU,
}

interface HomeProps {
  readonly view: View;
}

const Home: React.FC<HomeProps> = ({ view = View.HOME }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const currentURI = window.location.hash.substr(1);

    if (currentURI === "email") {
      dispatch(createToggleSubscribeModalAction({ isOpen: true }));
    }
  }, []);

  const getView = useCallback(() => {
    switch (view) {
      case View.MENU: {
        return <MenuContent isDineIn={false} />;
      }
      default:
        return (
          <>
            <ImageCarousel />
            <HoursAndLocation />
            <Subscribe />
            <About />
          </>
        );
    }
  }, [view]);

  return (
    <>
      <AnnouncementModal />
      <SubscribeModal />
      <OrderingModal />
      <Header />
      {getView()}
      <Footer />
    </>
  );
};

export default Home;
