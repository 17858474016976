import { RootState } from "../types/state";
import { OrderingPartners } from "../components/Ordering/config";

export const isAnnouncementModalOpen = (state: RootState): boolean => {
  return state.modals.isAnnouncementModalOpen;
};

export const isSubscribeEmailModalOpen = (state: RootState): boolean => {
  return state.modals.isSubscribeModalOpen;
};

export const isOrderingModalOpen = (state: RootState): boolean => {
  return state.modals.isOrderingModalOpen;
};

export const isItemDetailsModalOpen = (state: RootState): boolean => {
  return state.modals.isItemDetailsModalOpen;
};

export const getOrderingPartner = (state: RootState): OrderingPartners => {
  return state.modals.orderingPartner || OrderingPartners.GRUBHUB;
};

export const getItemId = (state: RootState): string => {
  return state.modals.itemId ?? "";
};
