import { OrderingPartners } from "../components/Ordering/config";

export enum ModalActions {
  ToggleAnnouncementModal = "Modals/ToggleAnnouncementModal",
  ToggleSubscribeModal = "Modals/ToggleSubscribeModal",
  ToggleOrderingModal = "Modals/ToggleOrderingModal",
  ToggleItemDetailsModal = "Modals/ToggleItemDetailsModal",
}

export interface ToggleAnnouncementModal {
  readonly type: ModalActions.ToggleAnnouncementModal;
  readonly isOpen: boolean;
}

export interface ToggleSubscribeModalAction {
  readonly type: ModalActions.ToggleSubscribeModal;
  readonly isOpen: boolean;
}

export interface ToggleOrderingModalAction {
  readonly type: ModalActions.ToggleOrderingModal;
  readonly isOpen: boolean;
  readonly partner?: OrderingPartners;
}

export interface ToggleItemDetailsModal {
  readonly type: ModalActions.ToggleItemDetailsModal;
  readonly isOpen: boolean;
  readonly itemId?: string;
}

export const createToggleAnnouncementModalAction = (
  props: Omit<ToggleAnnouncementModal, "type">
): ToggleAnnouncementModal => {
  return {
    type: ModalActions.ToggleAnnouncementModal,
    ...props,
  };
};

export const createToggleSubscribeModalAction = (
  props: Omit<ToggleSubscribeModalAction, "type">
): ToggleSubscribeModalAction => {
  return {
    type: ModalActions.ToggleSubscribeModal,
    ...props,
  };
};

export const createToggleOrderingModalAction = (
  props: Omit<ToggleOrderingModalAction, "type">
): ToggleOrderingModalAction => {
  return {
    type: ModalActions.ToggleOrderingModal,
    ...props,
  };
};

export const createToggleItemDetailsModalAction = (
  props: Omit<ToggleItemDetailsModal, "type">
): ToggleItemDetailsModal => {
  return {
    type: ModalActions.ToggleItemDetailsModal,
    ...props,
  };
};
