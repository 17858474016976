import React, { useEffect } from "react";
import "./styles.css";
import { useDispatch } from "react-redux";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./themes/theme";
import ReactGA from "react-ga";
import { createToggleSubscribeModalAction } from "./actions/modals";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home, { View } from "./Home";
import DineIn from "./components/DineIn";
import MenuContent from "./components/Content/MenuContent";

const App = (): JSX.Element => {
  const dispatch = useDispatch();
  ReactGA.initialize("UA-121664088-1");
  ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    const currentURI = window.location.hash.substr(1);

    if (currentURI === "email") {
      dispatch(createToggleSubscribeModalAction({ isOpen: true }));
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <Switch>
            <Route path={"/order"}>
              <DineIn />
            </Route>
            <Route path={"/menu"}>
              <Home view={View.MENU} />
            </Route>
            <Route path={"/dinein"}>
              <MenuContent isDineIn={true} />
            </Route>
            <Route
              path={["/order-online", "/assets/menu.pdf"]}
              component={() => {
                window.location.replace(
                  "https://www.toasttab.com/new-peking/v3/?mode=fulfillment"
                );
                return null;
              }}
            ></Route>
            <Route path={"/"}>
              <Home view={View.HOME} />
            </Route>
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
