import React from "react";
import "./styles.css";

const About: React.FC = () => {
  return (
    <section
      className={"sectionContainer"}
      role={"complementary"}
      aria-label={"About Us"}
    >
      <header>
        <h1 id={"about"}>About Us</h1>
      </header>
      <div className={"aboutContainer"}>
        <img
          className={"aboutImage"}
          src={`${process.env.PUBLIC_URL}/images/menu/egg_rolls.png`}
          alt="Dining is available"
        />
        <div className={"aboutContent"}>
          <p>
            Since 2004, New Peking Chinese Restaurant has been serving the
            Crystal Lake, IL and surrounding area with quality Chinese dishes
            from various regions of China including Cantonese, Mandarin, and
            Szechuan. New Peking offers a casual and contemporary dine-in
            atmosphere so you could feel as if you are dining in China at the
            restaurant.
          </p>
          <p>
            New Peking is a local and family owned restaurant that strives to
            provide you with an excellent Chinese dining experience.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
