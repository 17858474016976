export interface CarouselConfigItem {
  readonly name: string;
  readonly image: string;
}

export const CarouselConfig: CarouselConfigItem[] = [
  { name: "Egg Rolls", image: "images/menu/egg_rolls.png" },
  { name: "Crab Rangoons", image: "images/menu/crab_rangoons.png" },
  { name: "Pot Stickers (Pan Fried)", image: "images/menu/pot_stickers.png" },
  { name: "Egg Drop Soup", image: "images/menu/egg_drop_soup.png" },
  {
    name: "General Tso's Chicken",
    image: "images/menu/general_tso_chicken.png",
  },
  {
    name: "Sweet and Sour Chicken",
    image: "images/menu/sweet_and_sour_chicken.png",
  },
  { name: "Moo Goo Gai Pan", image: "images/menu/moo_goo_gai_pan.png" },
  { name: "Mongolian Beef", image: "images/menu/mongolian_beef.png" },
  {
    name: "Beef with Broccoli and Carrots",
    image: "images/menu/beef_with_broccoli.png",
  },
  {
    name: "Shrimp with Vegetables in Garlic Sauce",
    image: "images/menu/shrimp_with_vegetables.png",
  },
  {
    name: "Salt and Pepper Fish Fillet",
    image: "images/menu/salt_and_pepper_fish.png",
  },
  { name: "Roasted Duck", image: "images/menu/roasted_duck.png" },
  { name: "Chicken Fried Rice", image: "images/menu/chicken_fried_rice.png" },
  { name: "Chicken Lo Mein", image: "images/menu/chicken_lo_mein.png" },
  { name: "Beef Chow Fun", image: "images/menu/beef_chow_fun.png" },
  {
    name: "Chicken with Broccoli and Carrots Combo",
    image: "images/menu/chicken_with_broccoli_combo.png",
  },
  {
    name: "Chicken with Cashew Nuts Combo",
    image: "images/menu/chicken_with_cashew_combo.png",
  },
  {
    name: "General Tso's Chicken Combo",
    image: "images/menu/general_tso_chicken_combo.png",
  },
  {
    name: "Kung Pao Beef Combo",
    image: "images/menu/kung_pao_beef_combo.png",
  },
  {
    name: "Sesame Chicken Combo",
    image: "images/menu/sesame_chicken_combo.png",
  },
  {
    name: "Beef with Vegetables in Garlic Sauce Combo",
    image: "images/menu/beef_with_vegetables_combo.png",
  },
  {
    name: "Egg Foo Young Combo",
    image: "images/menu/egg_foo_young_combo.png",
  },
];
