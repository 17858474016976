import blue from "@material-ui/core/colors/blue";
import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  palette: {
    secondary: {
      main: blue[900],
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});
