import React from "react";

export const NoodleDescription = (): JSX.Element => {
  return (
    <div className={"itemPriceContainer"}>
      <div className={"noodlePrice"}>
        <strong>Vegetable:</strong> $11.25
      </div>
      <div className={"noodlePrice"}>
        <strong>Chicken:</strong> $11.50
      </div>
      <div className={"noodlePrice"}>
        <strong>Beef:</strong> $11.95
      </div>
      <div className={"noodlePrice"}>
        {" "}
        <strong>Pork:</strong> $11.50
      </div>
      <div className={"noodlePrice"}>
        <strong>Shrimp:</strong> $12.50
      </div>
      <div className={"noodlePrice"}>
        {" "}
        <strong>House Special:</strong> $13.95
      </div>
    </div>
  );
};
