import {
  ModalActions,
  ToggleAnnouncementModal,
  ToggleItemDetailsModal,
  ToggleOrderingModalAction,
  ToggleSubscribeModalAction,
} from "../../actions/modals";
import { ModalState } from "../../types/state";

type HandledActions =
  | ToggleAnnouncementModal
  | ToggleSubscribeModalAction
  | ToggleOrderingModalAction
  | ToggleItemDetailsModal;

const initialState: ModalState = {
  isAnnouncementModalOpen: false,
  isSubscribeModalOpen: false,
  isOrderingModalOpen: false,
  orderingPartner: undefined,
  isItemDetailsModalOpen: false,
  itemId: undefined,
};

const modalReducer = (
  state: ModalState = initialState,
  action: HandledActions
): ModalState => {
  switch (action.type) {
    case ModalActions.ToggleSubscribeModal:
      return { ...state, isSubscribeModalOpen: action.isOpen };
    case ModalActions.ToggleAnnouncementModal: {
      return { ...state, isAnnouncementModalOpen: action.isOpen };
    }
    case ModalActions.ToggleOrderingModal: {
      return {
        ...state,
        isOrderingModalOpen: action.isOpen,
        orderingPartner: action.isOpen ? action.partner : undefined,
      };
    }
    case ModalActions.ToggleItemDetailsModal: {
      return {
        ...state,
        isItemDetailsModalOpen: action.isOpen,
        itemId: action.isOpen ? action.itemId : undefined,
      };
    }
    default:
      return state;
  }
};

export default modalReducer;
