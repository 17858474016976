import { Button, Paper, Tab, Tabs } from "@material-ui/core";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import React, { useCallback, useState } from "react";
import "./styles.css";
import { MenuSelection } from "./types";
import { MenuAnchors } from "./config";
import { useDispatch } from "react-redux";
import { OrderingPartners } from "../../Ordering/config";
import { openOrderingModal } from "../../../utils/redirect";
import { useHistory, useLocation } from "react-router-dom";

const NAVIGATE_MENU_SELECTIONS = new Set([
  MenuSelection.HOURS,
  MenuSelection.LOCATION,
]);

const Menu: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [menuSelection, setMenuSelection] = useState<MenuSelection>(
    location.pathname === "/menu" ? MenuSelection.MENU : MenuSelection.HOURS
  );

  const handleMenuSelection = useCallback(
    (event, menuSelection: MenuSelection) => {
      const elementId = MenuAnchors[menuSelection];

      if (
        location.pathname !== "/" &&
        NAVIGATE_MENU_SELECTIONS.has(menuSelection)
      ) {
        history.push(`/#${elementId}`);
      }

      setMenuSelection(menuSelection);

      if (menuSelection === MenuSelection.ORDER) {
        return openOrderingModal(dispatch);
      } else if (menuSelection === MenuSelection.CATERING) {
        return openOrderingModal(dispatch, OrderingPartners.EZCATER);
      } else if (
        menuSelection === MenuSelection.MENU &&
        !location.pathname.includes("/menu")
      ) {
        history.push("/menu");
        return;
      }

      if (elementId) {
        setTimeout(() => {
          const elementToScrollTo = document.getElementById(elementId);

          if (elementToScrollTo) {
            elementToScrollTo.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }, 500);
      }
    },
    [dispatch, location]
  );

  const handleOrderNow = useCallback(() => {
    openOrderingModal(dispatch);
  }, [openOrderingModal, dispatch]);

  const handleLogoClick = useCallback(() => {
    if (location.pathname !== "/") {
      history.push("/");
      setMenuSelection(MenuSelection.HOURS);
    }
  }, [history]);

  return (
    <div className="menuContainer">
      <div className={"logoContainer"}>
        <div className={"logo"} onClick={handleLogoClick}>
          <div className={"logo-img"} />
          <div className={"nameContainer"}>
            <h1 className={"name"}>New Peking</h1>
            <h2 className={"name"}>Chinese Restaurant</h2>
            <h2 className={"name"}>新北京</h2>
          </div>
        </div>

        <div className={"orderNow"}>
          <Button variant="contained" color="primary" onClick={handleOrderNow}>
            Order Now!
          </Button>
        </div>
      </div>
      <nav id="nav">
        <Paper square>
          <Tabs
            centered
            value={menuSelection}
            onChange={handleMenuSelection}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="Menu"
          >
            <Tab
              icon={<QueryBuilderIcon />}
              label="Hours"
              value={MenuSelection.HOURS}
            />
            <Tab
              icon={<MenuBookIcon />}
              label="Menu"
              value={MenuSelection.MENU}
            />
            <Tab
              icon={<LocationOnIcon />}
              label="Location"
              value={MenuSelection.LOCATION}
            />
            <Tab
              icon={<ShoppingCartIcon />}
              label="Order"
              value={MenuSelection.ORDER}
            />
            <Tab
              icon={<PeopleIcon />}
              label="Catering"
              value={MenuSelection.CATERING}
            />
          </Tabs>
        </Paper>
      </nav>
    </div>
  );
};

export default Menu;
