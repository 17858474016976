import React, { useCallback } from "react";
import { Icon } from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";
import PhoneIcon from "@material-ui/icons/Phone";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

import "./styles.css";
import { useDispatch } from "react-redux";
import { openOrderingModal } from "../../../utils/redirect";
import { OrderingConfig, OrderingPartners } from "../../Ordering/config";

const Alert: React.FC = () => {
  const dispatch = useDispatch();

  const handleOrderNow = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      openOrderingModal(dispatch);
    },
    [openOrderingModal, dispatch]
  );

  return (
    <div className="top-alert">
      <div className={"alertContainer"}>
        <div className={"alertItem"}>
          <a
            href="https://goo.gl/maps/EkZBoRtoc962"
            target={"_blank"}
            rel="noreferrer"
          >
            <div className="infoContainer infoAddressContainer">
              <div>
                <Icon component={LocationOn} fontSize={"small"} />
              </div>
              <span>40 W Terracotta Ave #G, Crystal Lake, IL 60014</span>
            </div>
          </a>
        </div>
        <div className={"alertItem"}>
          <a href="tel:+18154596618">
            <div className="infoContainer">
              <div>
                <Icon component={PhoneIcon} fontSize={"small"} />
              </div>
              <span>(815) 459-6618</span>
            </div>
          </a>
        </div>
        <div className={"alertItem orderOnline"}>
          <a
            href={OrderingConfig[OrderingPartners.DIRECT].orderingUrl}
            onClick={handleOrderNow}
          >
            <div className="infoContainer lastInfoContainer">
              <div>
                <Icon component={ShoppingCartIcon} fontSize={"small"} />
              </div>
              <span>Order Online</span>
            </div>
          </a>
        </div>
      </div>
      <div className={"socialMedia"}>
        <ul>
          <li>
            <a
              href="https://www.facebook.com/newpekingcrystallake/"
              target={"_blank"}
              rel="noreferrer"
              aria-label={"facebook"}
            >
              <div>
                <Icon component={FacebookIcon} fontSize={"small"} />
              </div>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/newpekingcrystallake/"
              target={"_blank"}
              rel="noreferrer"
              aria-label={"instagram"}
            >
              <div>
                <Icon component={InstagramIcon} fontSize={"small"} />
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Alert;
