import React from "react";

export const FamilyMealDescription = (): JSX.Element => {
  return (
    <div className={"itemPriceContainer"}>
      <div className={"familyDinner"}>
        <strong>Starters:</strong> Egg Rolls, Vegetable Egg Rolls, Crab Rangoon.
      </div>
      <div className={"familyDinner"}>
        <strong>Soups:</strong> Egg Drop Soup, Hot & Sour Soup, Wonton Soup
      </div>
      <div className={"familyDinner"}>
        <strong>Rice/Noodle:</strong> Chicken Fried Rice, Vegetable Fried Rice,
        Chicken Lo Mein, Vegetable Lo Mein
      </div>
      <div className={"familyDinner"}>
        {" "}
        <strong>Entrées:</strong>
      </div>
      <div className={"familyDinner"}>
        <ul>
          <li>General Tso's Chicken</li>
          <li>Sesame Chicken</li>
          <li>Kung Pao Chicken</li>
          <li>Sweet & Sour Chicken</li>
          <li>Mongolian Beef (add $3.00)</li>
          <li>Beef with Broccoli and Carrots (add $1.50)</li>
          <li>Vegetarian's Delight</li>
        </ul>
      </div>
    </div>
  );
};
