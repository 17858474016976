export enum OrderingPartners {
  DIRECT,
  DIRECT_CATERING,
  GRUBHUB,
  DOORDASH,
  EZCATER,
}

interface OrderingPartnerConfig {
  orderingUrl: string;
  name: string;
}

export const OrderingConfig: {
  [partner in OrderingPartners]: OrderingPartnerConfig;
} = {
  [OrderingPartners.DIRECT]: {
    orderingUrl: "https://bit.ly/3gVoE4a",
    name: "Direct",
  },
  [OrderingPartners.DIRECT_CATERING]: {
    orderingUrl: "https://bit.ly/3vAwjZD",
    name: "Direct",
  },
  [OrderingPartners.DOORDASH]: {
    orderingUrl: "https://bit.ly/2GiH0fj",
    name: "Doordash",
  },
  [OrderingPartners.GRUBHUB]: {
    orderingUrl: "http://menus.fyi/275533",
    name: "Grubhub",
  },
  [OrderingPartners.EZCATER]: {
    orderingUrl: "https://bit.ly/33mhTjT",
    name: "ezCater",
  },
};
