import Button from "@material-ui/core/Button";
import React, { useCallback, useEffect } from "react";
import { OrderingConfig, OrderingPartners } from "../../config";
import { createToggleOrderingModalAction } from "../../../../actions/modals";
import { useDispatch } from "react-redux";
import "./styles.css";
import ReactGA from "react-ga";
import Map from "./Map";

export interface OrderingModalDeliveryProps {
  handleDirectOrder: () => void;
  readonly orderingPartner: OrderingPartners;
}

export const OrderingModalDelivery: React.FC<OrderingModalDeliveryProps> = ({
  orderingPartner,
  handleDirectOrder,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.modalview("/ordering/delivery");
  });

  const handleThirdPartyClick = useCallback(() => {
    window.open(OrderingConfig[orderingPartner].orderingUrl, "_blank");

    ReactGA.event({
      category: "Ordering",
      action: "GrubhubOrder",
    });

    dispatch(createToggleOrderingModalAction({ isOpen: false }));
  }, [dispatch]);

  const partnerName = OrderingConfig[orderingPartner].name;

  return (
    <>
      <p>
        Are you within a 5 mile radius of the restaurant? (40 W. Terra Cotta Ave
        Suite G, Crystal Lake, IL) We can deliver further distances via{" "}
        {partnerName}.
      </p>

      <div className={"deliveryOptionContainer"}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDirectOrder}
          className={"deliveryOption"}
        >
          Yes (Order directly with us)
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleThirdPartyClick}
          className={"deliveryOption"}
        >
          No (Order via {partnerName})
        </Button>

        <Map />
      </div>
    </>
  );
};
