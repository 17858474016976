import React from "react";
import "./styles.css";
import Hours from "./Hours";
import Address from "./Address";

const HoursAndLocation: React.FC = () => {
  return (
    <section className={"sectionContainer"} role={"main"}>
      <h1>Hours and Location</h1>

      <div className={"contentContainer"}>
        <div id="location" className={"locationContainer"}>
          <iframe
            title={"40 W. Terra Cotta Ave Suite G, Crystal Lake, Il 60014"}
            src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d2953.387210063785!2d-88.32422148381315!3d42.24890295010566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e0!4m0!4m5!1s0x880f729e73d110bb%3A0x32eafa88cdc0456d!2sNew%20Peking%20Chinese%20Restaurant%2C%2040%20W%20Terra%20Cotta%20Ave%20Ste%20G%2C%20Crystal%20Lake%2C%20IL%2060014!3m2!1d42.248899!2d-88.3220328!5e0!3m2!1sen!2sus!4v1604188355594!5m2!1sen!2sus"
            width="600"
            height="450"
            frameBorder="0"
            aria-hidden="false"
            tabIndex={0}
          />
        </div>
        <div className={"addressContainer"}>
          <Address />
        </div>
        <div className={"hoursContainer"}>
          <Hours />
        </div>
      </div>
    </section>
  );
};

export default HoursAndLocation;
