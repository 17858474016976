import React, { useCallback } from "react";
import { Button, Modal, ModalProps, Paper } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import "./styles.css";
import { RemoveScroll } from "react-remove-scroll";

interface WebsiteModalProps extends Omit<ModalProps, "onClose"> {
  readonly onClose: () => void;
}

const WebsiteModal: React.FC<WebsiteModalProps> = ({
  open,
  onClose,
  children,
}) => {
  const handleCloseButton = useCallback(() => {
    onClose();
  }, []);

  const Wrapper = open ? RemoveScroll : React.Fragment;

  return (
    <Wrapper>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby={"Announcement"}
        aria-describedby={"Announcements"}
      >
        <Paper square className={"modalContainer"}>
          <>
            <div className={"modalHeader"}>
              <Button
                variant={"contained"}
                color={"default"}
                startIcon={<Close />}
                onClick={handleCloseButton}
              >
                Close
              </Button>
            </div>
            {children}
          </>
        </Paper>
      </Modal>
    </Wrapper>
  );
};

export default WebsiteModal;
