interface Hours {
  readonly dayOfWeekLabel: string;
  readonly hours: string;
}

export const HOURS: Hours[] = [
  { dayOfWeekLabel: "Sunday", hours: "11:30 am - 8:45 pm" },
  { dayOfWeekLabel: "Monday", hours: "Closed" },
  { dayOfWeekLabel: "Tuesday", hours: "11:30 am - 8:45 pm" },
  { dayOfWeekLabel: "Wednesday", hours: "11:30 am - 8:45 pm" },
  { dayOfWeekLabel: "Thursday", hours: "11:30 am - 8:45 pm" },
  { dayOfWeekLabel: "Friday", hours: "11:30 am - 9:45 pm" },
  { dayOfWeekLabel: "Saturday", hours: "11:30 am - 9:45 pm" },
];
