import React, { useCallback, useState } from "react";
import jsonp from "jsonp";
import { Button, TextField } from "@material-ui/core";

interface MailChimpProps {
  readonly action: string;
}

enum MailchimpStatus {
  EMPTY,
  SENDING,
  DUPLICATE,
  ERROR,
  SUCCESS,
}

const MailChimp: React.FC<MailChimpProps> = ({ action }) => {
  const [email, setEmail] = useState<string>("");
  const [status, setStatus] = useState<MailchimpStatus>(MailchimpStatus.EMPTY);

  const sendData = useCallback(
    (url) => {
      setStatus(MailchimpStatus.SENDING);
      jsonp(url, { param: "c" }, (err, data) => {
        if (data.msg.includes("already subscribed")) {
          setStatus(MailchimpStatus.DUPLICATE);
        } else if (err) {
          setStatus(MailchimpStatus.ERROR);
        } else if (data.result !== "success") {
          setStatus(MailchimpStatus.ERROR);
        } else {
          setStatus(MailchimpStatus.SUCCESS);
        }
      });
    },
    [setStatus]
  );

  const handleSubmit = useCallback(
    (evt) => {
      evt.preventDefault();

      const path = `${action}&EMAIL=${email}`;
      const url = path.replace("/post?", "/post-json?");
      sendData(url);
    },
    [setStatus, sendData, email]
  );

  const handleEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    [setEmail]
  );

  return (
    <>
      <form onSubmit={handleSubmit} aria-label={"Your e-mail address"}>
        <label>
          <TextField label="Your e-mail address" onChange={handleEmailChange} />
        </label>
        <Button
          disabled={
            status === MailchimpStatus.SENDING ||
            status === MailchimpStatus.SUCCESS
          }
          variant={"contained"}
          color={"default"}
          onClick={handleSubmit}
        >
          Subscribe
        </Button>

        <div className="msg-alert">
          {status === MailchimpStatus.SENDING && <p>Subscribing...</p>}
          {status === MailchimpStatus.SUCCESS && (
            <p>Success! You are now subscribed for updates and promotions!</p>
          )}
          {status === MailchimpStatus.DUPLICATE && (
            <p>You are already subscribed.</p>
          )}
          {status === MailchimpStatus.DUPLICATE && (
            <p>We're sorry, something went wrong. Please try again later.</p>
          )}
        </div>
      </form>
    </>
  );
};

export default MailChimp;
