export const DineInTables = [
  "1",
  "2",
  "3",
  "B4",
  "B5",
  "B6",
  "7",
  "8",
  "9",
  "10",
  "11",
];
