import React from "react";
import { Circle, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import MarkerIcon from "../../../../../assets/images/marker-icon.png";
import L, { LatLng, Map as LeafletMap } from "leaflet";

const icon = L.icon({
  iconUrl: MarkerIcon,
  iconSize: [24, 36],
  iconAnchor: [12, 36],
});
const center = new LatLng(42.248781, -88.32202);

class Map extends React.PureComponent {
  private mapReference: L.Map | null = null;

  onCreate = (map: LeafletMap): void => {
    this.mapReference = map;

    this.mapReference.fitBounds(center.toBounds(15000));
  };

  render(): JSX.Element {
    return (
      <MapContainer
        whenCreated={this.onCreate}
        center={center}
        className={"deliveryRadiusMap"}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[42.248781, -88.32202]} icon={icon}>
          <Popup>
            <p>New Peking Chinese Restaurant</p>
            <p>40 W. Terracotta Ave #G, Crystal Lake, IL 60014</p>
          </Popup>
        </Marker>
        <Circle
          center={center}
          radius={8040}
          pathOptions={{ color: "#6593f1" }}
        />
      </MapContainer>
    );
  }
}

export default Map;
