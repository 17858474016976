import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { HOURS } from "./config";

const Hours: React.FC = () => {
  const dayOfWeek = new Date().getDay();

  return (
    <>
      <h2 id={"hours"}>Hours</h2>
      <TableContainer component={Paper}>
        <Table aria-label="Hours">
          <TableBody>
            {HOURS.map((hourDetails, index) => {
              return (
                <TableRow
                  selected={dayOfWeek === index}
                  key={hourDetails.dayOfWeekLabel}
                >
                  <TableCell>
                    <strong>{hourDetails.dayOfWeekLabel}</strong>
                  </TableCell>
                  <TableCell align="right">{hourDetails.hours}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Hours;
