import React, { useCallback } from "react";
import { Button } from "@material-ui/core";
import { DineInTables } from "./config";
import "./styles.css";

const Home = (): JSX.Element => {
  const handleTableClick = useCallback((tableNumber: string) => {
    window.open(
      `https://www.toasttab.com/new-peking/order-and-pay/${tableNumber}`,
      "_blank"
    );
  }, []);

  return (
    <section className={"dineInContainer"}>
      <h1>Order & Pay at Table</h1>

      <p>
        Thank you for dining with New Peking today! Please select your table
        number below to begin.
      </p>

      <div className={"diningTableContainer"}>
        {DineInTables.map((tableNumber) => {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleTableClick(tableNumber)}
              key={tableNumber}
            >
              Table {tableNumber}
            </Button>
          );
        })}
      </div>
    </section>
  );
};

export default Home;
