import {
  getOrderingPartner,
  isOrderingModalOpen,
} from "../../../selectors/announcement";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./styles.css";
import WebsiteModal from "../../Modal";
import { createToggleOrderingModalAction } from "../../../actions/modals";
import { OrderingConfig, OrderingPartners } from "../config";
import { OrderingModalInstructions } from "./Instructions";
import { OrderingModalDelivery } from "./Delivery";
import ReactGA from "react-ga";

enum OrderingStage {
  Instructions,
  Delivery,
}

const OrderingModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isOrderingModalOpen);
  const orderingPartner = useSelector(getOrderingPartner);
  const [currentStage, setCurrentStage] = useState(OrderingStage.Instructions);

  useEffect(() => {
    if (isOpen) {
      ReactGA.modalview("/ordering");
    }

    if (!isOpen) {
      setCurrentStage(OrderingStage.Instructions);
    }
  }, [isOpen]);

  const handleClose = useCallback(() => {
    dispatch(createToggleOrderingModalAction({ isOpen: false }));
  }, [dispatch]);

  const handleDirectOrder = useCallback(() => {
    if (orderingPartner === OrderingPartners.EZCATER) {
      window.open(
        OrderingConfig[OrderingPartners.DIRECT_CATERING].orderingUrl,
        "_blank"
      );
    } else {
      window.open(
        OrderingConfig[OrderingPartners.DIRECT].orderingUrl,
        "_blank"
      );
    }

    ReactGA.event({
      category: "Ordering",
      action: "DirectOrder",
    });

    handleClose();
  }, [handleClose, orderingPartner]);

  const handleDeliveryOrder = useCallback(() => {
    setCurrentStage(OrderingStage.Delivery);

    ReactGA.event({
      category: "Ordering",
      action: "Delivery",
    });
  }, [setCurrentStage]);

  const getStageContent = useCallback(() => {
    switch (currentStage) {
      case OrderingStage.Instructions:
        return (
          <OrderingModalInstructions
            handleDirectOrder={handleDirectOrder}
            handleDeliveryOrder={handleDeliveryOrder}
          />
        );
      case OrderingStage.Delivery:
        return (
          <OrderingModalDelivery
            handleDirectOrder={handleDirectOrder}
            orderingPartner={orderingPartner}
          />
        );
      default:
        return null;
    }
  }, [currentStage, handleDirectOrder, handleDeliveryOrder]);

  return (
    <WebsiteModal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby={"Order Online"}
    >
      <>
        <h1>Ordering Online</h1>

        {getStageContent()}
      </>
    </WebsiteModal>
  );
};

export default OrderingModal;
