import React, { useCallback } from "react";
import "./styles.css";
import { createToggleAnnouncementModalAction } from "../../actions/modals";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga";

const Announcement: React.FC = () => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    ReactGA.modalview("/announcement");
    dispatch(createToggleAnnouncementModalAction({ isOpen: true }));
  }, []);

  /**
  return (
    <div className={"announcementContainer"} onClick={handleClick}>
      <p>
        We are closed for a vacation until May 29th, we will reopen for normal
        business hours on May 30th.
      </p>
    </div>
  );*/
  return null;
};

export default Announcement;
