import React from "react";
import "./Alert/styles.css";
import Alert from "./Alert";
import Menu from "./Menu";
import Announcement from "../Announcement";
import "./styles.css";

const Header: React.FC = () => (
  <section id="header" role={"banner"}>
    <Announcement />
    <Alert />
    <Menu />
  </section>
);

export default Header;
