import React from "react";
import Carousel from "react-material-ui-carousel";
import ImageCarouselItem from "./ImageCarouselItem";
import { CarouselConfig } from "./config";
import "./styles.css";

const ImageCarousel: React.FC = () => {
  return (
    <div
      className={"carouselContainer"}
      role={"complementary"}
      aria-label={"Images of Food"}
    >
      <Carousel className="carousel" indicators={false} interval={5000}>
        {CarouselConfig.map((item, index) => {
          return <ImageCarouselItem key={index} item={item} />;
        })}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
