import React from "react";
import { Card, CardMedia, Grid, Typography } from "@material-ui/core";
import { CarouselConfigItem } from "../config";
import "./styles.css";

interface ImageCarouselProps {
  readonly item: CarouselConfigItem;
}

const ImageCarouselItem: React.FC<ImageCarouselProps> = ({ item }) => {
  return (
    <Card raised className="Banner">
      <Grid container spacing={0} className="BannerGrid">
        <CardMedia
          className="media"
          image={`${process.env.PUBLIC_URL}/${item.image}`}
          title={`Image of ${item.name}`}
        >
          <Typography className="MediaCaption">{item.name}</Typography>
        </CardMedia>
      </Grid>
    </Card>
  );
};

export default ImageCarouselItem;
