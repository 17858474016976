import Button from "@material-ui/core/Button";
import React from "react";
import "./styles.css";

export interface OrderingModalInstructionsProps {
  handleDirectOrder: () => void;
  handleDeliveryOrder: () => void;
}

export const OrderingModalInstructions: React.FC<OrderingModalInstructionsProps> =
  ({ handleDirectOrder, handleDeliveryOrder }) => {
    return (
      <>
        <p>Welcome! Would you like to order for pickup or delivery?</p>

        <div className={"fulfillmentOptionContainer"}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDirectOrder}
            className={"fulfillmentOption"}
          >
            Pickup
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={"fulfillmentOption"}
            onClick={handleDeliveryOrder}
          >
            Delivery
          </Button>
        </div>
      </>
    );
  };
