export enum SocialMedia {
  Facebook,
  Twitter,
  Instagram,
}

interface SocialMediaConfigProps {
  url: string;
}

export const SocialMediaConfig: {
  [socialMedia in SocialMedia]: SocialMediaConfigProps;
} = {
  [SocialMedia.Facebook]: {
    url: "https://www.facebook.com/newpekingcrystallake/",
  },
  [SocialMedia.Instagram]: {
    url: "https://www.instagram.com/newpekingcrystallake/",
  },
  [SocialMedia.Twitter]: { url: "https://twitter.com/new_peking" },
};
