import { Button } from "@material-ui/core";
import { isAnnouncementModalOpen } from "../../../selectors/announcement";
import React, { useCallback } from "react";
import { createToggleAnnouncementModalAction } from "../../../actions/modals";
import { useSelector, useDispatch } from "react-redux";

import "./styles.css";
import WebsiteModal from "../../Modal";
import { openOrderingModal } from "../../../utils/redirect";

const AnnouncementModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isAnnouncementModalOpen);

  const handleClose = useCallback(() => {
    dispatch(createToggleAnnouncementModalAction({ isOpen: false }));
  }, [dispatch]);

  const handleOrderNow = useCallback(() => {
    dispatch(createToggleAnnouncementModalAction({ isOpen: false }));
    openOrderingModal(dispatch);
  }, [openOrderingModal, dispatch]);

  return (
    <WebsiteModal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby={"Announcement"}
      aria-describedby={"Announcements"}
    >
      <>
        <h1>Holiday Hours</h1>
        <p>We will be open on New Year's Day.</p>

        <p>
          <strong>
            Dine-in services will not be available during those days
          </strong>
          , all orders will be takeout/delivery only.
        </p>

        <p>
          <mark>
            We highly recommend pre-ordering your meal on our website to ensure
            we will be able to process it.{" "}
          </mark>
          <div className={"orderingButton"}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOrderNow}
            >
              Order Now!
            </Button>
          </div>
        </p>

        <h1>Pickup Instructions</h1>
        <p>
          If you would like to pick up your order, you will find your order
          prepared on the pickup table.
        </p>
      </>
    </WebsiteModal>
  );
};

export default AnnouncementModal;
