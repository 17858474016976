import {
  OrderingConfig,
  OrderingPartners,
} from "../components/Ordering/config";
import ReactGA from "react-ga";
import { Dispatch } from "redux";
import { detect } from "detect-browser";
import { createToggleOrderingModalAction } from "../actions/modals";
const browser = detect();

export const openLegacyOrdering = (): void => {
  window.open(OrderingConfig[OrderingPartners.DIRECT].orderingUrl, "_blank");
  ReactGA.event({
    category: "Ordering",
    action: "DirectOrder",
  });
};

export const openOrderingModal = (
  dispatch: Dispatch,
  partner = OrderingPartners.GRUBHUB
): void => {
  const browserVersion = browser?.version?.split(".").map(Number);

  if (!Array.isArray(browserVersion) || browserVersion.length < 1) {
    return openLegacyOrdering();
  }

  const [major] = browserVersion;

  if (
    (browser?.name === "safari" ||
      browser?.name === "ios" ||
      browser?.name === "ios-webview") &&
    major < 14
  ) {
    return openLegacyOrdering();
  }

  if (browser?.name === "ie") {
    return openLegacyOrdering();
  }

  dispatch(createToggleOrderingModalAction({ isOpen: true, partner }));
};
