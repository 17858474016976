import {
  getItemId,
  isItemDetailsModalOpen,
} from "../../../selectors/announcement";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import items from "../../../assets/config/items.json";

import "./styles.css";
import WebsiteModal from "../../Modal";
import { createToggleItemDetailsModalAction } from "../../../actions/modals";
import { WebsiteItem } from "../../../assets/config/types";
import {
  cleanName,
  formatPrice,
  getItemImageUrl,
} from "../../Content/MenuContent";
import { Button } from "@material-ui/core";
import { openOrderingModal } from "../../../utils/redirect";
import { useHistory } from "react-router-dom";
import { NoodleDescription } from "../../Content/Description/NoodleDescription";
import { FamilyMealDescription } from "../../Content/Description/FamilyMealDescription";

interface WebsiteItemMap {
  [itemId: string]: WebsiteItem;
}

interface ItemDetailsModalProps {
  readonly isDineIn: boolean;
}

const ItemDetailsModal: React.FC<ItemDetailsModalProps> = ({
  isDineIn = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isOpen = useSelector(isItemDetailsModalOpen);
  const itemId = useSelector(getItemId);
  const item = (items as WebsiteItemMap)?.[itemId];

  useEffect(() => {
    return history.listen(() => {
      if (history.action === "POP") {
        dispatch(createToggleItemDetailsModalAction({ isOpen: false }));
      }
    });
  }, [history]);

  const handleClose = useCallback(() => {
    history.goBack();
    dispatch(createToggleItemDetailsModalAction({ isOpen: false }));
  }, [dispatch]);

  const handleOrderNow = useCallback(() => {
    openOrderingModal(dispatch);
  }, [openOrderingModal, dispatch]);

  if (item == null) {
    return null;
  }

  const hasLarge = item.largePrice > 0;
  const hasImage = !!item.imageUrl;
  const itemImage = getItemImageUrl(itemId);

  return (
    <WebsiteModal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby={"Item Details"}
    >
      <>
        <h1>{cleanName(item.name)}</h1>
        {hasImage && itemImage && (
          <img className={"itemDetailsImage"} src={itemImage} alt={item.name} />
        )}

        <p className={"itemDetailsDescription"}>{item.description}</p>

        {item.groupName?.includes("Family") && <FamilyMealDescription />}

        {item.basePrice > 0 && (
          <div className={"itemPrice"}>
            {!hasLarge ? (
              formatPrice(item.basePrice)
            ) : (
              <>
                <p>
                  <span className={"itemSmallPrice"}>
                    <strong>Small: </strong> {formatPrice(item.basePrice)}
                  </span>
                  <strong>Large: </strong>{" "}
                  {formatPrice(item.basePrice + item.largePrice)}
                </p>
              </>
            )}
          </div>
        )}

        {item.groupName?.includes("Noodle") && <NoodleDescription />}

        {!isDineIn && (
          <div className={"itemDetailsOrder"}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOrderNow}
            >
              Order Now!
            </Button>
          </div>
        )}
      </>
    </WebsiteModal>
  );
};

export default ItemDetailsModal;
