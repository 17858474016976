import React, { useCallback } from "react";
import "./styles.css";
import MailChimp from "./MailChimp";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import EmailIcon from "@material-ui/icons/Email";

import { IconButton } from "@material-ui/core";
import { SocialMedia, SocialMediaConfig } from "./config";
import { createToggleSubscribeModalAction } from "../../../actions/modals";
import { useDispatch } from "react-redux";

const Subscribe: React.FC = () => {
  const dispatch = useDispatch();

  const handleClick = useCallback(
    (socialMedia: SocialMedia) => {
      window.open(SocialMediaConfig[socialMedia].url, "_blank");
    },
    [SocialMediaConfig]
  );

  const handleNewsletterClick = useCallback(() => {
    dispatch(createToggleSubscribeModalAction({ isOpen: true }));
  }, [dispatch]);

  return (
    <section
      className={"sectionContainer"}
      role={"complementary"}
      aria-label={"About Us"}
    >
      <header>
        <h1>Subscribe</h1>
      </header>
      <div className={"aboutContainer"}>
        <div className={"aboutContent"}>
          <p>
            Subscribe to our e-mail newsletter, facebook, instagram, and twitter
            to receive updates from New Peking Chinese Restaurant! Subscribers
            receive periodic promotions and updates from our restaurant.
          </p>

          <div>
            <IconButton onClick={() => handleClick(SocialMedia.Facebook)}>
              <FacebookIcon /> Facebook
            </IconButton>
            <IconButton onClick={() => handleClick(SocialMedia.Instagram)}>
              <InstagramIcon /> Instagram
            </IconButton>
            <IconButton onClick={() => handleClick(SocialMedia.Twitter)}>
              <TwitterIcon /> Twitter
            </IconButton>
            <IconButton onClick={handleNewsletterClick}>
              <EmailIcon /> Email Newsletter
            </IconButton>
          </div>

          <div>
            <MailChimp action="https://newpeking.us7.list-manage.com/subscribe/post?u=f752ccede7e03daf316395f77&amp;id=95f5ec7e74" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
