import React, { useCallback, useEffect, useState } from "react";
import "./styles.css";
import groups from "../../../assets/config/menu.json";
import { createToggleItemDetailsModalAction } from "../../../actions/modals";
import { useDispatch } from "react-redux";
import { openOrderingModal } from "../../../utils/redirect";
import { OrderingConfig, OrderingPartners } from "../../Ordering/config";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "../../Arrows";
import { useHistory } from "react-router-dom";
import scrollIntoViewIfNeeded from "scroll-into-view-if-needed";
import scrollIntoView from "scroll-into-view";
import ItemDetailsModal from "../../ItemDetails/Modal";
import { NoodleDescription } from "../Description/NoodleDescription";
import { FamilyMealDescription } from "../Description/FamilyMealDescription";
import { WebsiteGroup } from "../../../assets/config/types";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatPrice = (priceString: number): string => {
  return formatter.format(priceString);
};

export const cleanName = (name: string): string => {
  return name.replace(/[^\w\s\\(\\)\\*\\&\\.\\/']/gi, "");
};

export const getItemImageUrl = (itemId: string): string => {
  return `${process.env.PUBLIC_URL}/images/imports/${itemId}.jpg` ?? "";
};

const getAnchorLink = (groupName: string): string => {
  return groupName.replace(/\s+/g, "_");
};

interface MenuContentProps {
  readonly isDineIn: boolean;
}

interface GroupNameProps {
  readonly anchorLink: string;
  readonly visibleAnchorLink: string | null;
  readonly group: WebsiteGroup;
  readonly onScroll: (anchorLink: string) => void;
  readonly itemId: string;
}

const GroupName: React.FC<GroupNameProps> = ({
  anchorLink,
  visibleAnchorLink,
  group,
  onScroll,
  itemId,
}) => {
  return (
    <div
      id={`menu-${anchorLink}`}
      className={`menuGroupName ${
        anchorLink === visibleAnchorLink ? "selected" : null
      }`}
      onClick={() => onScroll(anchorLink)}
      role="button"
      key={itemId}
      itemID={anchorLink}
      tabIndex={0}
    >
      <div className="card">{group.name}</div>
    </div>
  );
};

const MenuContent: React.FC<MenuContentProps> = ({ isDineIn }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sectionRefs = React.useRef(
    new Map<string, HTMLHeadingElement | null>()
  );
  const headerRef = React.useRef<HTMLHeadingElement>(null);
  const [visibleAnchorLink, setVisibleAnchorLink] = useState<string | null>(
    "Starters"
  );
  let scrollY = 0;

  const getDimensions = (ele: HTMLElement) => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
      height,
      offsetTop,
      offsetBottom,
    };
  };

  const handleScroll = () => {
    const currentHeaderRef = headerRef.current;
    if (currentHeaderRef == null) {
      return;
    }

    if (scrollY != window.scrollY) {
      scrollY = window.scrollY;
      const { height: headerHeight } = getDimensions(currentHeaderRef);
      const scrollPosition = window.scrollY + headerHeight;

      const selected = Array.from(sectionRefs.current.entries()).find(
        ([, ele]) => {
          if (ele) {
            const { offsetBottom, offsetTop } = getDimensions(ele);
            return scrollPosition > offsetTop && scrollPosition < offsetBottom;
          }
        }
      );

      if (selected) {
        const [anchorLink] = selected;

        const menuElement = document.querySelector(
          `#menu-${anchorLink}`
        ) as HTMLHeadingElement;

        scrollIntoViewIfNeeded(menuElement, {
          scrollMode: "if-needed",
          inline: "center",
          behavior: "auto",
          block: "nearest",
        });

        if (anchorLink !== visibleAnchorLink) {
          setVisibleAnchorLink(anchorLink);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleAnchorLink]);

  const handleItemClick = useCallback((itemId: string) => {
    const basePath = isDineIn ? "/dinein" : "/menu";

    history.push(`${basePath}/#${itemId}`);
    dispatch(createToggleItemDetailsModalAction({ isOpen: true, itemId }));
  }, []);

  const handleOrderNow = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      openOrderingModal(dispatch);
    },
    [openOrderingModal, dispatch]
  );

  const scrollToGroup = useCallback(
    (anchorLink) => {
      const element = document.querySelector(
        `#${anchorLink}`
      ) as HTMLHeadingElement;

      if (element == null) {
        return;
      }

      scrollIntoView(
        element,
        {
          align: {
            top: 0,
            topOffset: 50,
          },
        },
        () => setVisibleAnchorLink(anchorLink)
      );
    },
    [visibleAnchorLink, document]
  );

  return (
    <section className={"sectionContainer"} role={"main"}>
      <ItemDetailsModal isDineIn={isDineIn} />
      <div className={"onlineMenuContainer"}>
        <header className={"menuHeader"} ref={headerRef}>
          <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
            {groups.map((group) => {
              const anchorLink = getAnchorLink(group.name);
              return (
                <GroupName
                  key={group.name}
                  group={group}
                  anchorLink={anchorLink}
                  onScroll={() => scrollToGroup(anchorLink)}
                  visibleAnchorLink={visibleAnchorLink}
                  itemId={anchorLink}
                />
              );
            })}
          </ScrollMenu>
        </header>

        <h1 className={"menuName"}>
          {isDineIn ? "Dine-in Menu" : "Takeout Menu"}
        </h1>

        {!isDineIn && (
          <p>
            Prices are subject to change. Please visit our{" "}
            <a
              href={OrderingConfig[OrderingPartners.DIRECT].orderingUrl}
              onClick={handleOrderNow}
            >
              online ordering platform
            </a>{" "}
            for the latest prices. Prices may differ from 3rd party delivery
            platforms, order direct for the best prices.
          </p>
        )}

        {groups.map((group) => (
          <div
            key={`group-${group.name}`}
            className={"groupContainer"}
            id={`menu-location-${getAnchorLink(group.name)}`}
            ref={(el) => sectionRefs.current.set(getAnchorLink(group.name), el)}
          >
            <h2 id={getAnchorLink(group.name)}>{group.name}</h2>
            {group.description && <p>{group.description}</p>}

            <div className={"itemContainer"}>
              {group.items.map((item) => {
                const hasImage = !!item.imageUrl;
                const hasLarge = item.largePrice > 0;

                return (
                  <div
                    key={item.name}
                    className={"menuItem"}
                    onClick={() => handleItemClick(item.id)}
                  >
                    <div className={"menuItemContainer"}>
                      <div className={"itemName"}>{cleanName(item.name)}</div>
                      <div className={"itemDescription"}>
                        {item.description}
                      </div>
                      <div className={"itemPrice"}>
                        {group.name === "Noodles" && <NoodleDescription />}
                        {group.name === "Family Meal" && (
                          <FamilyMealDescription />
                        )}
                        {item.basePrice > 0 && (
                          <>
                            {!hasLarge || isDineIn ? (
                              formatPrice(
                                isDineIn
                                  ? item.basePrice + item.largePrice
                                  : item.basePrice
                              )
                            ) : (
                              <>
                                <div className={"itemPriceContainer"}>
                                  <span className={"itemSmallPrice"}>
                                    <strong>Small: </strong>{" "}
                                    {formatPrice(item.basePrice)}
                                  </span>
                                  <span className={"itemLargePrice"}>
                                    <strong>Large: </strong>{" "}
                                    {formatPrice(
                                      item.basePrice + item.largePrice
                                    )}
                                  </span>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {hasImage && (
                      <div className={"itemImage"}>
                        <img src={getItemImageUrl(item.id)} alt={item.name} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MenuContent;
