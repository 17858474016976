import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import React from "react";

const Address: React.FC = () => (
  <>
    <h2>Address</h2>

    <p>
      We're located at{" "}
      <a href="https://goo.gl/maps/EkZBoRtoc962">
        40 W. Terra Cotta Ave #G, Crystal Lake, IL
      </a>{" "}
      at the intersection of Walkup Road and Route 176. We're in the same mall
      as the 7-Eleven and just minutes from downtown Crystal Lake.
    </p>

    <h2>Contact Details</h2>

    <TableContainer component={Paper} className={"hoursTable"}>
      <Table aria-label="Hours">
        <TableBody>
          <TableRow>
            <TableCell>
              <strong>Phone Number</strong>
            </TableCell>
            <TableCell align="right">
              📞 <a href="tel:+18154596618">(815) 459-6618</a>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <h2>Reservations</h2>

    <p>Please call us at (815) 459-6618 to place your dine-in reservation!</p>
  </>
);

export default Address;
